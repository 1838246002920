import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/experience split sights and culture - destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  ExperienceSplitList1,
  ExperienceSplitList2,
} from './ExperienceSplitList';

const ExperienceSplit = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(
      Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE
    )}`;
  }, []);

  return (
    <div
      id={Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE}
      className='destination'
    >
      <div className='destination_hero'>
        <img
          src={heroImg}
          alt={`${Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE} hero`}
        />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>
          EXPERIENCE SPLIT SIGHTS AND CULTURE
        </h1>
      </div>
      <div className='destination_container container'></div>
      <br />
      <br />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='destination_subtext container'>
          <h3>
            Cornaro hotel is proudly named after the central bastion which is
            located inside the historic old city's pedestrian zone. Besides the
            defensive fortification and the palace itself, the hotel is
            surrounded by all of the main tourist attractions, museums,
            churches…
          </h3>
        </div>
        <DestinationFeatures
          id={Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE + '1'}
          list={ExperienceSplitList1}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <div className='destination_subtext container'>
            <h2>WALKING THROUGH THE MUSEUMS</h2>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE + '2'}
          list={ExperienceSplitList2}
          isReversed={true}
        />
      </ScrollAnimation>
    </div>
  );
};

export default ExperienceSplit;
