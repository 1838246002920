import poolRules from '../../../../assets/documents/03_restaurants/Tabla smjernica EN TISAK 2022.pdf';
import wineList from '../../../../assets/documents/03_restaurants/Rooftop wine 2024.pdf';

const images = require.context(
  '../../../../assets/imgs/04_restaurants/02_enjoy',
  true
);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

interface Feature {
  description: JSX.Element;
  imgPath: string;
  link?: string;
  linkName?: string;
}

const TERRACES: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Terraces</h2>
      <p>
        Step into the seductive setting of our Terraces, a warm and welcoming
        space ideal for breakfast followed by morning coffee, afternoon snacks
        or an evening cocktail. With its miniature lemon trees and Mediterranean
        plants, beautiful open terraces represent an essential gathering place
        which gives you the impression of a real Dalmatian atmosphere in the
        heart of Split.
      </p>
      <p className='warning_text'>
        *Private and formal events can be arranged in this venue. For
        reservations, additional information and payment methods, please contact
        us.
      </p>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('terraces')) || '',
  link: wineList,
  linkName: 'WINE CHART',
};

const ROOFTOP: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Split Rooftop bar</h2>
      <p>
        Crowning the hotel on the 6th floor, the Rooftop Bar offers spectacular
        views over the city center and islands. In the summertime, the bar
        invites you to linger over your favorite drink and enjoy live music
        while our chef works the grill magic, serving up dinner specials. Take
        in the surroundings and enjoy the beautiful sunset as the hours slip by.
      </p>
      <p>
        Sit back and enjoy the sun rays from the comfort of a refreshing
        decorative pool bar. Stunning views of the city center, the blue
        Adriatic and the islands provide the perfect backdrop while sipping your
        favorite drink.
      </p>
      <p className='warning_text'>
        <b>
          Split Rooftop Bar is closed from November 2024. till April 2025.
        </b>
      </p>
      <p className='warning_text'>
        *Private and formal events can be arranged in this venue. For
        reservations, additional information and payment methods, please contact
        us.
      </p>
      <a
        href='https://book.easytablebooking.com/book/?id=1b2c5&lang=auto'
        target='_blank'
        rel='noreferrer'
        className='opacity_transition_low button'
      >
        <div>BOOK A TABLE</div>
      </a>
      <p>Incentive Events Rooftop Bar Split reservations</p>
      <a
        href='mailto:meetings@cornarohotel.com'
        target='_blank'
        rel='noreferrer'
        className='opacity_transition_low button'
      >
        <div>CONTACT</div>
      </a>
    </>
  ),
  imgPath: imageList.find((x) => x.includes('rooftop')) || '',
  link: poolRules,
  linkName: 'DECORATIVE POOL BAR INSTRUCTIONS',
};

const EnjoyList: Array<Feature> = [TERRACES, ROOFTOP];

export default EnjoyList;
