import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/experience a place-destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  ExperiencePlaceList1,
  ExperiencePlaceList2,
} from './ExperiencePlaceList';

const ExperiencePlace = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.EXPERIENCE_A_PLACE)}`;
  }, []);

  return (
    <div id={Constants.EXPERIENCE_A_PLACE} className='destination'>
      <div className='destination_hero'>
        <img src={heroImg} alt={`${Constants.EXPERIENCE_A_PLACE} hero`} />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>EXPERIENCE A PLACE</h1>
      </div>
      <div className='destination_container container'>
        <h2>ITS SENSATIONS, AROMAS, FLAVOURS AND COLOURS</h2>
      </div>
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='destination_subtext container'>
          <h2>LOVE LOCAL, LOVE SPLIT</h2>
          <h3>
            The service provided by Cornaro Hotel incorporates the “hotel in the
            city, city in the hotel” philosophy, providing you with a unique
            Split experience.
          </h3>
          <br />
          <h3>
            CORNARO HOTEL IS LOCATED IN THE HEART OF SPLIT. THE CITY'S RICH
            HISTORY LEAVES ITS CLUES FOR YOU TO ENJOY FROM THE MOMENT YOU STEP
            OUTSIDE THE HOTEL TO START EXPLORING.
          </h3>
        </div>
        <DestinationFeatures
          id={Constants.EXPERIENCE_A_PLACE + '1'}
          list={ExperiencePlaceList1}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <div className='destination_subtext container'>
            <h2>CREATE YOUR SPLIT EXPERIENCE</h2>
            <h3>
              When you start exploring our city and our destination, if you need
              a transfer, rent a car, boat rent, excursions or sightseeing feel
              free to contact us. We are happy to help!
            </h3>
            <br />
            <h2>LOCAL POPULAR PLACES - CHOOSE YOUR OWN SPLIT EXPERIENCE </h2>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.EXPERIENCE_A_PLACE + '2'}
          list={ExperiencePlaceList2}
          isReversed={true}
        />
      </ScrollAnimation>
    </div>
  );
};

export default ExperiencePlace;
