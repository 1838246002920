import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/destination experience - destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  destinationExperienceList1,
  destinationExperienceList2,
} from './DestinationExperienceList';

const DestinationExperience = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.DESTINATION_EXPERIENCE)}`;
  }, []);

  return (
    <div id={Constants.DESTINATION_EXPERIENCE} className='destination'>
      <div className='destination_hero'>
        <img src={heroImg} alt={`${Constants.DESTINATION_EXPERIENCE} hero`} />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>Destination experience</h1>
      </div>
      <div className='destination_container container'></div>
      <br />
      <br />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='destination_subtext container'>
          <h3>
            Once you've enjoyed your time in the city, we can make you
            experience the attractions and adventures of beautiful smaller
            cities and islands surrounding our hotel and the city of Split
          </h3>
        </div>
        <DestinationFeatures
          id={Constants.DESTINATION_EXPERIENCE + '1'}
          list={destinationExperienceList1}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <div className='destination_subtext container'>
            <h2>VISIT TO THE DALMATIAN ISLANDS</h2>
            <h3>
              The multitude of islands, islets and cliffs makes the Adriatic
              special. Each of them has its own cultural and historical
              monuments, natural beauties and local people.
            </h3>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.DESTINATION_EXPERIENCE + '2'}
          list={destinationExperienceList2}
          isReversed={true}
        />
      </ScrollAnimation>
    </div>
  );
};

export default DestinationExperience;
