import rooftopImg from '../../../../assets/imgs/home/01_authenticity/rooftop.jpg';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

const Authenticity = () => {
  return (
    <div id={Constants.AUTHENTICITY} className='authenticity'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation
            ScrollAnimation
            animateIn='fadeInUp'
            duration={500}
            delay={100}
          >
            <h2 className='text_center'>EXPERIENCE AUTHENTICITY</h2>
          </ScrollAnimation>
          <div className='authenticity_container'>
            <ScrollAnimation animateIn='fadeInUp' duration={1000} delay={150}>
              <div className='authenticity_container_text'>
                <div>
                  <h2 className='title_underline_light_small'>
                    SPLIT ROOFTOP BAR
                  </h2>
                  <p>
                    The perfect mix of drinks, views and unique atmosphere.
                    Great choice for light bites and a few drinks surrounded by
                    views.
                  </p>
                  <p>
                    Sit back and enjoy the sun rays from the comfort of a
                    refreshing decorative pool bar. Stunning views of the city
                    center, the blue Adriatic and the islands provide the
                    perfect backdrop while sipping your favorite drink.
                  </p>
                  <p className='warning_text'>
                    Split Rooftop Bar is closed from November 2024. till April
                    2025.
                  </p>
                  <a
                    href='https://book.easytablebooking.com/book/?id=1b2c5&lang=auto'
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low button'
                  >
                    <div>BOOK A TABLE</div>
                  </a>
                  <p>Incentive Events Rooftop Bar Split reservations</p>
                  <a
                    href='mailto:meetings@cornarohotel.com'
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low button'
                  >
                    <div>CONTACT</div>
                  </a>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' duration={1000} delay={200}>
              <div className='authenticity_container_img'>
                <img src={rooftopImg} alt='rooftop bar' />
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Authenticity;
