import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../utils/Constants';
import ScrollAnimation from '../../utils/ScrollAnimation';
import variables from '../../style/variables.scss';

const DestinationFeatures = ({
  id,
  list,
  isReversed,
}: {
  id: string;
  list: Array<Feature>;
  isReversed: boolean;
}) => {
  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);
  const transitionSpeed: number = 500;

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImg = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 870,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={`${id}-features`} className='destinationFeatures'>
      <div className='destinationFeatures_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <div
            className={`destinationFeatures_container_content destinationFeatures_container_content${
              isReversed ? `_reverse` : ''
            }`}
          >
            <ScrollAnimation animateIn='fadeInUp' delay={100}>
              <div className='destinationFeatures_container_content_text'>
                <Slider ref={textRef} {...slickText}>
                  {list.map((feature) => (
                    <>{feature.description}</>
                  ))}
                </Slider>
                {list.length > 1 && (
                  <div className='destinationFeatures_container_content_buttons'>
                    <div
                      className='opacity_transition_low'
                      onClick={() => prev()}
                    >
                      {Constants.arrowLeft(variables.secondaryColor)}
                    </div>
                    <div
                      className='opacity_transition_low'
                      onClick={() => next()}
                    >
                      {Constants.arrowRight(variables.secondaryColor)}
                    </div>
                    <div className='destinationFeatures_container_content_buttons_counter'>
                      {activeSlide + 1} / {list.length}
                    </div>
                  </div>
                )}
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' delay={150}>
              <div className='destinationFeatures_container_content_img'>
                <Slider ref={imgRef} {...slickImg}>
                  {list.map((feature) => (
                    <img
                      src={feature.imgPath}
                      alt={`${Constants.EXPERIENCE_A_PLACE} ${
                        list[list.indexOf(feature)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default DestinationFeatures;
