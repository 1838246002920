import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/dalmatian state of mind local words - destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  dalmatianMindList1,
  dalmatianMindList2,
  dalmatianMindList3,
} from './DalmatianMindList';

const DalmatianMind = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(
      Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS
    )}`;
  }, []);

  return (
    <div
      id={Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS}
      className='destination'
    >
      <div className='destination_hero'>
        <img
          src={heroImg}
          alt={`${Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS} hero`}
        />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>
          DALMATIAN STATE OF MIND STORY LOCAL WORDS
        </h1>
      </div>
      <div className='destination_container container'></div>
      <br />
      <br />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <DestinationFeatures
          id={Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS + '1'}
          list={dalmatianMindList1}
          isReversed={false}
        />
        <DestinationFeatures
          id={Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS + '2'}
          list={dalmatianMindList2}
          isReversed={true}
        />
        <DestinationFeatures
          id={Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS + '3'}
          list={dalmatianMindList3}
          isReversed={false}
        />
      </ScrollAnimation>
    </div>
  );
};

export default DalmatianMind;
