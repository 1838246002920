import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/escape to create your activities in nature - destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  excapeToCreateList1,
  excapeToCreateList2,
  excapeToCreateList3,
} from './ExcapeToCreateList';

const ExcapeToCreate = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(
      Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE
    )}`;
  }, []);

  return (
    <div
      id={Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE}
      className='destination'
    >
      <div className='destination_hero'>
        <img
          src={heroImg}
          alt={`${Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE} hero`}
        />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>
          Escape to create your activities in nature
        </h1>
      </div>
      <div className='destination_container container'></div>
      <br />
      <br />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='destination_subtext container'>
          <h3>
            Besides the historical attractions of the city, the vicinity of the
            woods, parks and beaches can make you enjoy the beauties of nature
            in just a few steps.
          </h3>
          <h2>MARJAN</h2>
          <p>
            Marjan, a hill overlooking Split, with the highest peak of 178
            meters, has always been the most striking part of the picture of
            Split. It is seldom possible to experience such harmony of nature
            and urbanism; on the one hand a densely populated city in its full
            splendor, and on the other a peninsula of almost untouched nature.
          </p>
        </div>
        <DestinationFeatures
          id={Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE + '1'}
          list={excapeToCreateList1}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <div className='destination_subtext container'>
            <h2>ESCAPE TO CREATE YOUR BEACH MEMORIES</h2>
            <h3>
              The multitude of islands, islets and cliffs makes the Adriatic
              special. Each of them has its own cultural and historical
              monuments, natural beauties and local people.
            </h3>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE + '2'}
          list={excapeToCreateList2}
          isReversed={true}
        />
        <DestinationFeatures
          id={Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE + '3'}
          list={excapeToCreateList3}
          isReversed={false}
        />
      </ScrollAnimation>
    </div>
  );
};

export default ExcapeToCreate;
