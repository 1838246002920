import img1 from '../../../../assets/imgs/05_destination/experienceSplit/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/experienceSplit/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/experienceSplit/3.jpg';

const PLACE_1: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        Diocletian's Palace
      </h3>
      <p>
        The story of Split has been going on for 17 centuries, ever since the
        Roman emperor Diocletian decided to build a palace on that peninsula
        near the great Roman city of Salona, where he would spend the last years
        of his life in peace. In those 1700 years, the Palace steadily grew into
        a city which nowadays attracts visitors with its rich tradition,
        magnificent history, and beauty of natural and cultural heritage.
      </p>
      <h3 className='title_underline_complementary_small'>Prokurative</h3>
      <p>
        Prokurative (Square of the Republic) is a large plaza located west of
        the waterfront that resembles the Venetian St. Mark's Square and which
        is surrounded on three sides by Neo-Renaissance buildings with arches
        after which the square was named.
      </p>
      <h3 className='title_underline_complementary_small'>Pjaca</h3>
      <p>
        Pjaca (Narodni trg; Square of the People) is the first inhabited part of
        Split outside Diocletian's Palace, next to its western wall.
      </p>
    </>
  ),
  imgPath: img1,
};

const PLACE_2: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        Cathedral of St. Dujam (St. Domnius)
      </h3>
      <p>
        Among European cathedrals, Split has the oldest building - the mausoleum
        of the Roman emperor Diocletian, inside of which ancient pagan remnants
        are mixed with medieval Christian and modern heritage.
      </p>
      <h3 className='title_underline_complementary_small'>
        Peristil (The Peristyle)
      </h3>
      <p>
        The Peristyle finds its place among many temples as the central square
        of the Palace, intended for the celebration of Emperor Diocletian as the
        living son of Jupiter. The Emperor would appear under the architrave of
        the central part of Protyron, and his subjects would approach him
        kneeling down and kissing the hem of his scarlet cloak, or even
        prostrating in front of him.
      </p>
      <h3 className='title_underline_complementary_small'>The Vestibule</h3>
      <p>
        The Vestibule is the first part of the imperial corridor that led from
        the Peristyle to Diocletian's residential part of the Palace. The old
        imperial lobby still appears monumental today. Southeast of the
        Vestibule is a medieval area, within which the oldest early Romanesque
        house from the tenth century is located.
      </p>
    </>
  ),
  imgPath: img2,
};

const MUSEUM: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        Archaeological Museum
      </h3>
      <p>
        The museum has tens of thousands of different archaeological objects,
        which are arranged in the following collections: Prehistoric,
        Greco-Hellenistic, Roman-Provincial, Late Antique, Medieval, Numismatic
        and Epigraphic.
      </p>
      <h3 className='title_underline_complementary_small'>Split City Museum</h3>
      <p>
        Split city museum and the promenade above the north wall of the Palace
        Although it was created in 1915, museum opened to the public in 1992 for
        the first time with a complete permanent exhibition.
      </p>
      <h3 className='title_underline_complementary_small'>
        Emanuel Vidović Gallery
      </h3>
      <p>
        The Emanuel Vidović Gallery is dedicated to the character and work of
        the painter Emanuel Vidović, and is conceived as a place of collection,
        processing, research, protection and presentation of the painter's
        material and spiritual heritage.
      </p>
      <h3 className='title_underline_complementary_small'>
        Ivan Meštrović Gallery
      </h3>
      <p>
        The Meštrović Gallery houses the artist's masterpieces in marble,
        bronze, wood and plaster, which cover all periods of the artist's rich
        work until 1946.
      </p>
      <h3 className='title_underline_complementary_small'>
        Croatian National Theater Split
      </h3>
      <p>
        Originally opened in 1893, HNK SPLIT has lived in the area of Dobri for
        more than a century. It is the most important theater institution in
        Dalmatia.
      </p>
    </>
  ),
  imgPath: img3,
};

export const ExperienceSplitList1: Array<Feature> = [PLACE_1, PLACE_2];
export const ExperienceSplitList2: Array<Feature> = [MUSEUM];
