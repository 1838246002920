import { useEffect } from 'react';
import Constants from '../../utils/Constants';
import heroImg from '../../assets/imgs/06_b2b/b2b.webp';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../utils/ScrollAnimation';

const B2b = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(Constants.B2B)}`;
  }, []);

  return (
    <div id={Constants.B2B} className='b2b'>
      <div className='b2b_hero'>
        <img src={heroImg} alt={`${Constants.B2B} hero`} />
      </div>
      <div className='b2b_container container'>
        <h1 className='text_center'>CORNARO B2B</h1>
        <h2 className='text_center'>
          QUALITY IS OUR STANDARD AND INVESTMENT IN THE FUTURE
        </h2>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <p>
              This is a section specially designed for Cornaro Hotel partners
              and those who wish to become a Cornaro Partner.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              We know virtual inspections can never replicate the experience of
              being physically present in a space, using all your senses. But,
              if you can't arrive just yet, we are here to guide you through our
              virtual Cornaro experience.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>For more information, please contact us at:</p>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <p>
              <a
                href='mailto:reservations@cornarohotel.com'
                className='opacity_transition_low'
              >
                reservations@cornarohotel.com
              </a>
            </p>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' delay={300}>
            <p>
              <a href='tel:+385 21 644 205' className='opacity_transition_low'>
                +385 21 644 205
              </a>
            </p>
            <p>
              <a href='tel:+385 99 544 2005' className='opacity_transition_low'>
                +385 99 544 2005
              </a>
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>TRAVEL AGENCIES</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>Dear Partners,</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>
              We would like to thank you for collaborating with us! Cornaro
              Hotel offers the option of selling of its capacities through
              travel agencies which do not have an agreement and offers
              cooperation on the Work upon Request principle. You can check
              availability and make a reservation contactig us directly at:
            </p>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <p>
              <a
                href='mailto:reservations@cornarohotel.com'
                className='opacity_transition_low'
              >
                reservations@cornarohotel.com
              </a>
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>PRICES</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              Accommodation prices are liable to change in different periods,
              depending on demand for accommodation units. When making a
              specific enquiry or availability check, you should always also
              check the current price. The prices given are gross values, which
              means that they are subject to a pre-arranged commission.
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>TERMS OF PAYMENT</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              <b>{`1) If the partner pays the full amount (minus the commission in
              case applicable) in advance, at least 15 days before the guest's
              arrival, with an invoice, via bank transfer.`}</b>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>
              If paying via bank transfer, please use the following bank account
              no:
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <br />
            <div className='b2b_container_box'>
              <span>Company: Manas d.o.o.</span>
              <span>Adress: Barakovićeva 18, 21 000 Split</span>
              <span>Director: Stjepan Radović</span>
              <span>Telephone: +38521644200</span>
              <span>Fax: +38521644201</span>
              <span>VAT RegNo: 77290534017</span>
              <span>Bank-Name: Erste & Steiermaerkische Bank DD</span>
              <span>SWIFT-Code: ESBCHR22</span>
              <span>IBAN: HR1324020061100664517</span>
              <span>CURRENCY: EUR</span>
              <span>Contact person: Ivana Klarić (Hotel Manager)</span>
              <span>Contact e-mail: reservations@cornarohotel.com</span>
            </div>
            <br />
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={300}>
            <p>
              <i>
                The partner's credit card must be used as a booking guarantee
                until payment is received.
              </i>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={350}>
            <p>
              If a payment announced by the agency has not been made by the
              agency itself, but rather directly by the client, that payment
              will be treated as an individual payment and will not be regarded
              as the agency's dealings.
            </p>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' delay={400}>
            <p className='text_start'>
              <b>{`2) Bookings made less than 48 hours before arrival`}</b>
            </p>
            <ol className='text_start'>
              <li>Bookings made less than 48 hours before arrival</li>
              <li>
                The guest pays the full amount at reception, while the
                commission (if applicable) is paid to the partner after the
                service has been completed (at the end of the stay) and a bill
                has been sent.
              </li>
            </ol>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn='fadeInUp' delay={350}>
            <p>
              The partner's credit card must be used as a booking guarantee.
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>BOOKING CANCELLATIONS</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              Reservation is kept until 18:00h on the arrival day, if late
              arrival is not announced.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>
              Cancellation without charge is possible within 3 days (72 hours)
              prior to arrival. If it is cancelled after noted period, the hotel
              charges 100% of the whole amount. No show and reservation
              cancellation on the day of arrival, as well as arrivals before
              confirmed departure date will be charged 100% of total amount.
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>EVENTS</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              Special terms apply for guest accommodation during events with our
              caterers; for such events, terms of “query-based services” do not
              apply.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <h3>Additional services/service charge</h3>
            <p>
              Services that have not been pre-requested or paid will be charged
              directly to the customer, based on the price list of the
              accommodation unit that is applicable at the time when the service
              is provided.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <h3>Sale termination</h3>
            <p>
              In case of fully booked capacities, we reserve the right to
              suspend further sales to the partner and/or refuse to sell a
              larger number of rooms, regardless of whether sales via our
              individual channel at{' '}
              <a
                href='https://cornarohotel.com'
                className='opacity_transition_low'
                target='_blank'
                rel='noreferrer'
              >
                cornarohotel.com
              </a>{' '}
              is still open. Our goal is to continue with a successful
              partnership and good business results through our quality service
              and support. We look forward to working with you and welcoming our
              dear guests and partners!
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={300}>
            <p>
              Sincerely yours,
              <br />
              Cornaro Hotel Reservation Team
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>MICE</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              Whether you are hosting an intimate private gathering or a
              corporate event, we have the perfect downtown venues for you. Any
              special occasion can be arranged in a variety of different
              settings, indoors or on our beautiful open terraces.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>
              If you are hosting a webinar or hibrid meetings, we can also offer
              video production, live streaming, projections, video conferences,
              WiFi and wired Internet, and sound with lighting for your event.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <p>
              You can check availability and make a reservation contactig us
              directly at:{' '}
              <a
                href='mailto: meetings@cornarohotel.com'
                className='opacity_transition_low'
              >
                meetings@cornarohotel.com
              </a>
            </p>
          </ScrollAnimation>
        </ScrollAnimation>
        <br />
        <br />
        <br />
        <br />
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <h2 className='text_center'>CORPORATE</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              Cornaro Hotel offers the option of special conditions for business
              travel, holidays, corporate sport games, incentives and complete
              offers only for corporations/their employees that have a Corporate
              Business Cooperation Agreement in place.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>For more information contact us directly at:</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <p>
              <a
                href='mailto: meetings@cornarohotel.com'
                className='opacity_transition_low'
              >
                meetings@cornarohotel.com
              </a>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={250}>
            <br />
            <div className='b2b_container_box'>
              <div className='b2b_container_box_grid'>
                <div>GDS/IDS</div>
                <div>CHAIN CODE</div>
                <div>PROPERTY CODE</div>
              </div>
              <div className='b2b_container_box_grid'>
                <div>AMADEUS (1A)</div>
                <div>GD</div>
                <div>SPUAOR</div>
              </div>
              <div className='b2b_container_box_grid'>
                <div>SABRE (AA)</div>
                <div>GD</div>
                <div>372908</div>
              </div>
              <div className='b2b_container_box_grid'>
                <div>GALILEO/APOLLO (UA)</div>
                <div>GD</div>
                <div>G5595</div>
              </div>
              <div className='b2b_container_box_grid'>
                <div>WORLDSPAN (1P)</div>
                <div>GD</div>
                <div>SPUCO</div>
              </div>
            </div>
            <br />
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default B2b;
