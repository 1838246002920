import expPlaceImg from '../../../../assets/imgs/home/09_destinations/experience a place-destination.jpg';
import expSplitImg from '../../../../assets/imgs/home/09_destinations/experience split sights and culture - destination.jpg';
import destExpImg from '../../../../assets/imgs/home/09_destinations/destination experience - destination.jpg';
import escToCreateImg from '../../../../assets/imgs/home/09_destinations/escape to create your activities in nature - destination.jpg';
import traditionalFoodImg from '../../../../assets/imgs/home/09_destinations/story of traditional food and cornaro gastronomy - destination.jpg';
import stateOfMindImg from '../../../../assets/imgs/home/09_destinations/dalmatian state of mind local words - destination.jpg';
import Constants from '../../../../utils/Constants';

interface Destination {
  title: string;
  link: string;
  img: string;
}

export const DestinationsList: Array<Destination> = [
  {
    title: 'EXPERIENCE A PLACE',
    link: Constants.EXPERIENCE_A_PLACE,
    img: expPlaceImg,
  },
  {
    title: 'EXPERIENCE SPLIT SIGHTS AND CULTURE',
    link: Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE,
    img: expSplitImg,
  },
  {
    title: 'DESTINATION EXPERIENCE',
    link: Constants.DESTINATION_EXPERIENCE,
    img: destExpImg,
  },
  {
    title: 'ESCAPE TO CREATE YOUR ACTIVITIES IN NATURE',
    link: Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE,
    img: escToCreateImg,
  },
  {
    title: 'STORY OF TRADITIONAL FOOD AND CORNARO GASTRONOMY',
    link: Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY,
    img: traditionalFoodImg,
  },
  {
    title: 'DALMATIAN STATE OF MIND LOCAL WORDS',
    link: Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS,
    img: stateOfMindImg,
  },
];
