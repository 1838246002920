import img1 from '../../../../assets/imgs/05_destination/experiencePlace/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/experiencePlace/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/experiencePlace/3.jpg';
import img4 from '../../../../assets/imgs/05_destination/experiencePlace/4.jpg';

const LIVE_LOCAL_1: Feature = {
  description: (
    <>
      <p>
        Experience some of the most luxurious holiday destinations in Croatia
        such as Split, a city of culture, gastronomy and entertainment with
        activities for everyone. Welcome and enjoy your stay in the everlasting
        youth of Split in the central city Cornaro Hotel!
      </p>
      <p>
        Welcome to Split - the second largest city in Croatia which is economic,
        administrative, educational, sport and tourist center of Middle
        Dalmatia. Connected to a variety of Croatian islands, pearls of Adriatic
        like Hvar, Vis, Brač and Šolta, Split is an ideal starting point for
        various daily excursions throughout Dalmatia. Our friendly, professional
        and educated staff will gladly assist you to find your ideal place to
        visit and enjoy your stay in this beautiful Dalmatian town.
      </p>
    </>
  ),
  imgPath: img1,
};

const LIVE_LOCAL_2: Feature = {
  description: (
    <>
      <p>
        If you had to find that one place in Europe which will awake the
        historical feelings in you without hesitation, a warm welcome awaits you
        in Split. Even today when we mention Split, we think of the famous
        Diocletian's palace. The monumental palace is remarkable due to the fact
        that this Ancient Roman emperor's home evolved over the years and became
        an entire town known as Split. Walking around Split today, it is
        difficult to see where the palace ends and the city begins. The two are
        intricately combined. Some of the impressive original ruins include the
        fortification gates, Temple of Jupiter, underground Diocletian's cellars
        with passageways and Peristil. The importance of Diocletian's palace far
        transcends the local significance because of its level of preservation
        and the buildings of various historical periods which were built within
        its walls and today form the very heart of Split's history. Diocletian's
        palace is one of the most unique architectural constructions in Europe.
      </p>
      <p>
        There is not a city with stronger demand for life. The steps of life
        loudly echo here and the warm Mediterranean temperament lives in the
        citizens. In the evenings, Split's squares and streets come alive with
        youngsters and oldsters, visitors and hosts alike. Nature, past and
        social life are all connected in one irretrievably attractive unit.
        Split is a city that stays forever in a traveler's memory after the
        visit.
      </p>
    </>
  ),
  imgPath: img2,
};

const POPULAR_PLACES_1: Feature = {
  description: (
    <>
      <p>
        Experience some of the most luxurious holiday destinations in Croatia
        such as Split, a city of culture, gastronomy and entertainment with
        activities for everyone. Welcome and enjoy your stay in the everlasting
        youth of Split in the central city Cornaro Hotel!
      </p>
      <p>
        Welcome to Split - the second largest city in Croatia which is economic,
        administrative, educational, sport and tourist center of Middle
        Dalmatia. Connected to a variety of Croatian islands, pearls of Adriatic
        like Hvar, Vis, Brač and Šolta, Split is an ideal starting point for
        various daily excursions throughout Dalmatia. Our friendly, professional
        and educated staff will gladly assist you to find your ideal place to
        visit and enjoy your stay in this beautiful Dalmatian town.
      </p>
    </>
  ),
  imgPath: img3,
};

const POPULAR_PLACES_2: Feature = {
  description: (
    <>
      <p>
        If you had to find that one place in Europe which will awake the
        historical feelings in you without hesitation, a warm welcome awaits you
        in Split. Even today when we mention Split, we think of the famous
        Diocletian's palace. The monumental palace is remarkable due to the fact
        that this Ancient Roman emperor's home evolved over the years and became
        an entire town known as Split. Walking around Split today, it is
        difficult to see where the palace ends and the city begins. The two are
        intricately combined. Some of the impressive original ruins include the
        fortification gates, Temple of Jupiter, underground Diocletian's cellars
        with passageways and Peristil. The importance of Diocletian's palace far
        transcends the local significance because of its level of preservation
        and the buildings of various historical periods which were built within
        its walls and today form the very heart of Split's history. Diocletian's
        palace is one of the most unique architectural constructions in Europe.
      </p>
      <p>
        There is not a city with stronger demand for life. The steps of life
        loudly echo here and the warm Mediterranean temperament lives in the
        citizens. In the evenings, Split's squares and streets come alive with
        youngsters and oldsters, visitors and hosts alike. Nature, past and
        social life are all connected in one irretrievably attractive unit.
        Split is a city that stays forever in a traveler's memory after the
        visit.
      </p>
    </>
  ),
  imgPath: img4,
};

export const ExperiencePlaceList1: Array<Feature> = [
  LIVE_LOCAL_1,
  LIVE_LOCAL_2,
];
export const ExperiencePlaceList2: Array<Feature> = [
  POPULAR_PLACES_1,
  POPULAR_PLACES_2,
];
