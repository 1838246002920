import img1 from '../../../../assets/imgs/05_destination/traditionalFood/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/traditionalFood/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/traditionalFood/3.jpg';
import img4 from '../../../../assets/imgs/05_destination/traditionalFood/4.jpg';
import img5 from '../../../../assets/imgs/05_destination/traditionalFood/5.jpg';
import img6 from '../../../../assets/imgs/05_destination/traditionalFood/6.jpg';
import img7 from '../../../../assets/imgs/05_destination/traditionalFood/7.jpg';

const RESTAURANTS: Feature = {
  description: (
    <>
      <p>
        In the restaurants “Kralj Tomislav” and “Nodilo”, with an emphasis on
        unique combinations, exceptional flavors and freshness of local
        ingredients, the pleasure of dining is complemented by a pleasant
        atmosphere and impeccable service. Paying homage to the rich culinary
        heritage of Dalmatia, our dishes are inspired by the abundance of nature
        that surrounds us, fresh ingredients and traditional recipes with a
        modern touch. In addition to the above restaurants, the Cornaro hotel
        also has a beautiful Rooftop Bar, which offers views of the entire old
        town and islands. Drinks, live music and barbecue specialties from the
        hotel's chef can be enjoyed on the terrace.
      </p>
    </>
  ),
  imgPath: img1,
};

const DISHES_1: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Grilled Fish</h3>
      <p>
        All restaurants on the coast serve this dish. Even though it doesn't
        sound like anything special, it is tempting if you know that the fish is
        fresh and delivered to the restaurant by the fisherman himself, seasoned
        with locally made olive oil and herbs, and then expertly grilled.
      </p>
      <h3 className='title_underline_complementary_small'>Black Risotto</h3>
      <p>
        This authentic dish got its name from the cuttlefish ink, which paints
        it black. Despite its color, it's believed to be a natural
        antidepressant, so feel free to enjoy this dish with a big smile.
      </p>
      <h3 className='title_underline_complementary_small'>Brudet</h3>
      <p>
        A sea stew made with all sorts of seafood and fish, cooked with some
        quality wine. Simple ingredients with a special and secret way of
        cooking will make you wonder how you could live without it until now.
      </p>
      <h3 className='title_underline_complementary_small'>Pašticada</h3>
      <p>
        It's not all about fish and seafood — Dalmatian people are also experts
        in preparing meat. This dish features marinated beef stuffed with bacon
        and carrots, enriched with wine, and cooked for at least 6 hours. It's
        usually reserved for special occasions.
      </p>
    </>
  ),
  imgPath: img2,
};

const DISHES_2: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Peka</h3>
      <p>
        Prepared in a fireplace, Peka is a traditional cooking method under an
        iron dome covered with embers. Peka also takes a couple of hours to
        finish, but it's definitely worth the wait. Octopus or lamb are the most
        popular choices for Peka.
      </p>
      <h3 className='title_underline_complementary_small'>Prosciutto</h3>
      <p>
        One of the trademarks of Dalmatia, smoked in a unique way unlike any
        other prosciutto in the world. These slices disappear in seconds!
      </p>
      <h3 className='title_underline_complementary_small'>Soparnik</h3>
      <p>
        Two layers of thin dough stuffed with Swiss chard, parsley, and garlic
        combine to create Soparnik. It was the first Croatian dish to earn
        cultural heritage status.
      </p>
      <h3 className='title_underline_complementary_small'>Fritule</h3>
      <p>
        If you have a sweet tooth, this is for you. It is a Croatian festive
        dessert that looks like miniature doughnuts. Warning: highly addictive!
      </p>
      <h3 className='title_underline_complementary_small'>Rožata</h3>
      <p>
        Also included in the list of Croatian heritage dishes, Rožata is a
        custard pudding similar to crème caramel. A hint of lemon gives it its
        special flavor.
      </p>
    </>
  ),
  imgPath: img3,
};

const HERBS: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        Dalmatian Herbs on Your Plate
      </h3>
      <p>
        Some herbs, such as basil, feature regularly in the simple salads of the
        region, like the tomato and basil salad, a fresh and flavorsome
        accompaniment to any lunch. Others are core ingredients in some of
        Dalmatia's most traditional dishes. As with rosemary, parsley is an
        omnipresent ingredient in many Dalmatian dishes.
      </p>
      <h3 className='title_underline_complementary_small'>
        Dalmatian Salt / Salt Flower
      </h3>
      <p>
        Only pure nature can produce a substance as fine as Flower of Salt.
        Delicate petal-like crystals of virgin salt crust form on evaporating
        seawater during hot summer days. Moist, crunchy, and full of minerals
        essential for human health, Flower of Salt is too precious to be used
        for cooking.
      </p>
    </>
  ),
  imgPath: img4,
};

const MEDICAL_HERBS: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Rosemary</h3>
      <p>
        The most widely spread natural herb of Dalmatia, rosemary as a cultural
        plant has been known since the dawn of humanity. The best part is that
        you can just go outside, grab a plant, return home, and use it in your
        cooking.
      </p>
      <h3 className='title_underline_complementary_small'>Olive</h3>
      <p>
        This ancient plant remains an integral part of Dalmatian cuisine and
        culture. There is no home without olive oil and no island without olive
        oil production. Olive leaves are rich in vitamins A, B, C, and E,
        offering anti-viral, anti-bacterial, and anti-fungal benefits. Olive
        oil, a healthy liquid fat obtained from olives, contains antioxidants
        that help protect the body from cellular damage, reducing the risk of
        various health conditions. Extra virgin olive oil, with its bitter
        flavor, contains more antioxidants due to minimal processing.
      </p>
      <h3 className='title_underline_complementary_small'>Lavender</h3>
      <p>
        Lavender is widely used for the production of lavender oil, which serves
        as an antiseptic and in aromatherapy. Fresh leaves and petals can also
        be added to salads, soups, and cooked meals.
      </p>
      <h3 className='title_underline_complementary_small'>Sage</h3>
      <p>
        The medicinal and fragrant sage, known by its Latin name Salvia
        officinalis and regional Croatian names such as žalfija, divlji kuš,
        slavulja, and pitomi pelin, is used to make essential oils that help
        with ailments like respiratory and gastrointestinal infections and
        swelling. This plant grows wild as short bushes on rocky ground in
        sunny, dry areas.
      </p>
    </>
  ),
  imgPath: img5,
};

const WINES_1: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Dingač Nikolica</h3>
      <p>
        Dingač Nikolica is a premium red dry wine of controlled origin from the
        Dingač locality, on the steep southern slopes of the Pelješac peninsula.
        It is made from the autochthonous “Plavac mali” grape variety. This wine
        is adorned with balanced fruity aromas of maraschino cherries, prunes,
        and figs, with spice notes in the background.
      </p>
      <h3 className='title_underline_complementary_small'>Postup Nikolica</h3>
      <p>
        Postup Nikolica is a dry wine made from “Plavac mali” grapes grown in
        the famous locality of Postup. With its ruby color and full, harmonious
        taste accented by a note of bitterness, it pairs well with dark meat
        dishes, game, roasted blue fish, or squid.
      </p>
      <h3 className='title_underline_complementary_small'>Grgić Wines</h3>
      <p>
        The Grgić Vina company was established in 1996 by legendary Napa Valley
        winemaker, Croatian-born Miljenko “Mike” Grgich, his daughter Violet,
        and his nephew Ivo Jeramaz. Dedicated to the art of winemaking, Mike
        started the winery to produce the finest wines from native Dalmatian
        grapes.
      </p>
    </>
  ),
  imgPath: img6,
};

const WINES_2: Feature = {
  description: (
    <>
      <p>Main domestic varieties:</p>
      <ul>
        <li>
          Graševina (A variety that has flourished in Croatia and gained new
          meaning)
        </li>
        <li>Malvazija</li>
        <li>Merlot</li>
        <li>Chardonnay</li>
        <li>Cabernet Sauvignon</li>
        <li>Babić</li>
        <li>Rukatac</li>
        <li>Maraština</li>
        <li>Pošip</li>
        <li>Sauvignon</li>
        <li>Kujundžuša</li>
        <li>Teran</li>
        <li>Pinot</li>
        <li>Syrah</li>
        <li>Zinfandel</li>
        <li>Primitivo</li>
        <li>Bogdanuša</li>
        <li>Vugava</li>
        <li>Grk</li>
      </ul>
    </>
  ),
  imgPath: img7,
};

export const traditionalFoodList1: Array<Feature> = [RESTAURANTS];
export const traditionalFoodList2: Array<Feature> = [DISHES_1, DISHES_2];
export const traditionalFoodList3: Array<Feature> = [HERBS];
export const traditionalFoodList4: Array<Feature> = [MEDICAL_HERBS];
export const traditionalFoodList5: Array<Feature> = [WINES_1, WINES_2];
