import storyImg from '../../../assets/imgs/08_expirience/story/story.jpg';
import cornaroImg from '../../../assets/imgs/08_expirience/story/cornaro.jpg';
import priuliImg from '../../../assets/imgs/08_expirience/story/priuli.jpg';
import contariniImg from '../../../assets/imgs/08_expirience/story/contarini.jpg';
import nodiloImg from '../../../assets/imgs/08_expirience/story/nodilo.jpg';
import tomislavImg from '../../../assets/imgs/08_expirience/story/tomislav.jpg';

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const STORY: Feature = {
  description: (
    <>
      <p>
        The Cornaro Hotel is located in the heart of the ancient city of Split,
        a city characterized by a turbulent and rich history that still leaves
        its traces in the everyday life of the city. In the historic center
        which has been continuously inhabited for more than 1700 years, the
        silent whispers of history, originality of the present and warm
        Mediterranean temperament which lives in the citizens are intricately
        combined.
      </p>
    </>
  ),
  imgPath: storyImg,
};

const CORNARO: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Cornaro bastion</h2>
      <p>
        The boundaries of the Split historic core pedestrian area are defined by
        star-shaped Baroque bastions, which were built by Venetian governor of
        Dalmatia, Andrea Cornaro, in order to defend the city from the Turks.
        The central bastion was named after him and today represents the best
        preserved remnant of the Baroque city fortification.
      </p>
      <p>
        Located in the immediate vicinity of the bastion, Cornaro Hotel proudly
        carries the name of the central bastion, and its logotype outlines this
        silent witness of Split's courage.
      </p>
    </>
  ),
  imgPath: cornaroImg,
};

const PRIULI: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Priuli bastion</h2>
      <p>
        Bastion Priuli, forgotten treasure of the Baroque era, is located close
        to the Cornaro Hotel. Built in 1667 on a swampy area where once streams
        flowed, bastion Priuli was named after general Antonio Priuli. As one of
        the last bastions built in Split, Priuli has served as a defense against
        the Turkish invasion on the north-western part of the city center. After
        the danger from the Turks passed, bastion lost its defensive purpose and
        became private property. With Imperial decision which declared Split a
        free city in 1845, began the devastation of this significant cultural
        monument.
      </p>
      <p>Conference room in Cornaro Hotel was named after this bastion.</p>
    </>
  ),
  imgPath: priuliImg,
};

const CONTARINI: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Contarini bastion</h2>
      <p>
        A short walk from the Hotel Cornaro leads to the third bastion of
        star-shaped fortification, named after the Venetian doge Carlo
        Contarini. Like other bastions, Contarini is under the protection of
        UNESCO and conservators because of its sentimental, cultural, historical
        and tourist importance to the historical center of the city of Split.
      </p>
      <p>
        Conference hall in Cornaro Hotel is oriented towards the bastion
        Contarini, after which it was named.
      </p>
    </>
  ),
  imgPath: contariniImg,
};

const NODIL: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>Natko Nodilo</h2>
      <p>
        Originating from the old fisherman's family from the island of Mljet,
        Natko Nodilo was a famous Croatian politician, historian and publicist,
        born in 1843. Interested in the awakening of national consciousness, he
        teamed up with other intellectuals of populist beliefs in Split, thus
        creating a core of the future popular movement in Split that has spread
        throughout Dalmatia. He was dedicated to the union of Dalmatia with the
        rest of Croatia, respect for minorities and the equality of languages.
        He died in 1912 in the Croatian capital, Zagreb.
      </p>
      <p>
        Restaurant Nodilo in Cornaro Hotel was named after this distinguished
        personality of national revival in Dalmatia.
      </p>
    </>
  ),
  imgPath: nodiloImg,
};

const TOMISLAV: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>King Tomislav</h2>
      <p>
        As the first crowned Croatian king, Tomislav is famous for his
        distinctive art of war, the creation of the Croatian Kingdom and
        alliance with Byzantium. Because of the strong state and army, as well
        as a large increase of personal power and prestige, Tomislav has made
        significant things for the Croatian people in 10th century, including
        state and national survival of Croats, the development of trade and
        economy as well as the merger of the Dalmatian islands with the rest of
        Croatia.
      </p>
      <p>
        Restaurant King Tomislav in the Cornaro Hotel is named after one of the
        most celebrated figures in the Croatian history, the first Croatian king
        Tomislav.
      </p>
    </>
  ),
  imgPath: tomislavImg,
};

const featuresList: Array<Feature> = [
  STORY,
  CORNARO,
  PRIULI,
  CONTARINI,
  NODIL,
  TOMISLAV,
];

export default featuresList;
