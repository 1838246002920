import cityBreakImg from '../../../../assets/imgs/home/04_offers/split_city_break.jpg'
import stayImg from '../../../../assets/imgs/home/04_offers/stay.jpg'
import bookingImg from '../../../../assets/imgs/home/04_offers/booking.jpg'
import familyImg from '../../../../assets/imgs/home/04_offers/family.jpg'
import romanticImg from '../../../../assets/imgs/home/04_offers/romantic.jpg'
import singelImg from '../../../../assets/imgs/home/04_offers/single.jpg'
import urbanImg from '../../../../assets/imgs/home/04_offers/urban.jpg'

import SPLIT_CITIY_BREAK from '../../../../assets/documents/home/01_offers/city break dark.pdf';
import THREE_DAY_STAY from '../../../../assets/documents/home/01_offers/3 DAY STAY.pdf';
import DIRECT_BOOKING from '../../../../assets/documents/home/01_offers/DIRECT BOOKING - ADD VALUE.pdf'
import FAMILY from '../../../../assets/documents/home/01_offers/FAMILY.pdf';
import ROMANTIC from '../../../../assets/documents/home/01_offers/ROMANTIC.pdf';
import SINGLES_GETAWAY from '../../../../assets/documents/home/01_offers/SINGLES GETAWAY.pdf';
import URBAN_GETAWAY from '../../../../assets/documents/home/01_offers/URBAN GETAWAY.pdf';

interface Offer {
  offerTitle: string;
  offerLink: string;
  offerImg: string;
}

const OffersList: Array<Offer> = [
  {
    offerTitle: 'SPLIT CITY BREAK',
    offerLink: SPLIT_CITIY_BREAK,
    offerImg: cityBreakImg,
  },
  {
    offerTitle: '3 DAY STAY',
    offerLink: THREE_DAY_STAY,
    offerImg: stayImg,
  },
  {
    offerTitle: 'DIRECT BOOKING - ADD VALUE',
    offerLink: DIRECT_BOOKING,
    offerImg: bookingImg,
  },
  {
    offerTitle: 'FAMILY',
    offerLink: FAMILY,
    offerImg: familyImg,
  },
  {
    offerTitle: 'ROMANTIC',
    offerLink: ROMANTIC,
    offerImg: romanticImg,
  },
  {
    offerTitle: 'SINGLES GETAWAY',
    offerLink: SINGLES_GETAWAY,
    offerImg: singelImg,
  },
  {
    offerTitle: 'URBAN GETAWAY',
    offerLink: URBAN_GETAWAY,
    offerImg: urbanImg,
  },
];

export default OffersList;
