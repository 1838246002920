import img1 from '../../../../assets/imgs/05_destination/dalmatianMind/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/dalmatianMind/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/dalmatianMind/3.jpg';

const DALMATIAN_WORDS_1: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>URA</h3>
      <p>an hour, also stands for 1 a.m. and 1 p.m.</p>
      <h3 className='title_underline_complementary_small'>ČOVIK</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A man, a dear person, a trusted one, someone we're compassionate
        about. An acquaintance who gets us whatever we may need. Every Dalmatian
        person knows at least one.
      </p>
      <h3 className='title_underline_complementary_small'>FJAKA</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A psychophysical condition of a person wanting nothing. Mistaking
        „fjaka“ for laziness is an easy mistake to make. Unlike the latter,
        „fjaka“ is a blissful condition of body and mind the whole humankind is
        searching for. In India, „fjaka“ is attained by decades of meditation
        and fasting. In Dalmatia, it's simply a gift from God.
      </p>
    </>
  ),
  imgPath: img1,
};

const DALMATIAN_WORDS_2: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>JUŽINA</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />
        Rainy weather combined with low air pressure. Every Dalmatian resident
        blames „južina“ for their bad mood and lack of concentration. Even the
        greatest workaholics are unable to think, let alone move, when „južina“
        strikes.
      </p>
      <h3 className='title_underline_complementary_small'>BURA</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A cold wind blowing away everything in its path including the
        clouds, making weather sunny.
      </p>
      <h3 className='title_underline_complementary_small'>SUNCE</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />
        The Sun. <br />
        An absolute staple of Dalmatian lifestyle and, less importantly, the
        economy through tourism.
      </p>
      <h3 className='title_underline_complementary_small'>TOVAR</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A donkey, the most popular working animal of Dalmatia. A Split
        resident, the least popular working animal of Dalmatia, known for
        avoiding labor.
      </p>
    </>
  ),
  imgPath: img2,
};

const DALMATIAN_WORDS_3: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>ŠTEKAT</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />
        An outside terrace of a bar where a resident of Split tends to spend
        most of their days.
      </p>
      <h3 className='title_underline_complementary_small'>REDIKUL</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A derogatory term used to describe a cringeworthy person oblivious
        of their lapses.
      </p>
      <h3 className='title_underline_complementary_small'>PRIŠA</h3>
      <p>
        <span>
          <i>noun</i>
        </span>
        <br />A hurry.
      </p>
      <h3 className='title_underline_complementary_small'>POMALO</h3>
      <p>
        <span>
          <i>phrase</i>
        </span>
        <br />
        The most desired tempo of performance in Dalmatia and the polar opposite
        of „priša“.
      </p>
      <h3 className='title_underline_complementary_small'>ALA, ASTI</h3>
      <p>
        <span>
          <i>expressions; each used on its own, never together</i>
        </span>
        <br />
        Frequently used exclamations of joy, cheering and pleasant surprise, but
        also criticism, displeasure and anger. When used on its own, translates
        to „oh my“. When combined with a noun, translates to „holy“, such as
        'holy guacamole'.
      </p>
      <h3 className='title_underline_complementary_small'>TORCIDA</h3>
      <p>
        <span>
          <i>
            The supporters' group of HNK Hajduk Split, the local football club.
          </i>
        </span>
        <br />
        Torcida is the oldest European football firm, founded days after the
        conclusion of 1950 World Cup in Brazil, hence the name.
      </p>
    </>
  ),
  imgPath: img3,
};

export const dalmatianMindList1: Array<Feature> = [DALMATIAN_WORDS_1];

export const dalmatianMindList2: Array<Feature> = [DALMATIAN_WORDS_2];

export const dalmatianMindList3: Array<Feature> = [DALMATIAN_WORDS_3];
