import Constants from '../../../utils/Constants';
import heroImg from '../../../assets/imgs/08_expirience/expirience.webp';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import StoryBehindTheNameList from './StoryBehindTheNameList';

const StoryBehindTheName = () => {
  return (
    <div id={Constants.STORY_BEHIND_THE_NAME} className='story'>
      <div className='story_hero'>
        <img src={heroImg} alt={`${Constants.STORY_BEHIND_THE_NAME} hero`} />
      </div>
      <div className='story_container container'>
        <h1 className='text_center'>STORY BEHIND THE NAME</h1>
        <h2 className='text_center'>PERSONAL TOUCH BASED ON AUTHENTICITY</h2>
        {StoryBehindTheNameList.map((service, index) => (
          <ScrollAnimation
            key={index}
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <div
              className={index % 2 === 0 ? 'story_grid_reverse' : 'story_grid'}
            >
              <ScrollAnimation animateIn='fadeIn' delay={100}>
                <div className='story_grid_img'>
                  <img
                    src={service.imgPath}
                    alt={service.imgPath.split('/').pop()?.split('.')[0]}
                  />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' delay={150}>
                <div className='story_grid_description'>
                  {service.description}
                </div>
              </ScrollAnimation>
            </div>
          </ScrollAnimation>
        ))}
        <h3 className='text_center'>
          The excellent central city location of our hotel, its ancient history
          surroundings and interior elegance of luxurious and comfortable rooms
          make Cornaro Hotel a part of the top tourist and business offer of the
          city.
        </h3>
      </div>
    </div>
  );
};

export default StoryBehindTheName;
