import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Variables from '../../style/variables.scss';
import Constants from '../../utils/Constants';
import logo from '../../assets/imgs/logo_white.svg';
import menu from '../../assets/imgs/menu.jpg';
import scrollToPage from '../../utils/ScrollToLink';
import scrollDirection from '../../utils/ScrollDirection';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const direction = scrollDirection();

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');

  const handleLink = (target: string, page?: boolean) => {
    setOpenMenu(!openMenu);
    scrollToPage(target, location, navigate, page);

    setTimeout(() => {
      if (header !== null && headerBackground !== null && window.scrollY > 96) {
        headerBackground.style.top = '-10rem';
        header.style.top = '-10rem';
      }
    }, 600);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.borderBottom = 'unset';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-10rem';
        header.style.top = '-10rem';

        if (window.scrollY >= 96) {
          headerBackground.style.borderBottom = `1px solid ${Variables.secondaryColor}`;
        }
      }
    }
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');
    }
  }, [openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div
          className='nav_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img
            src={logo}
            alt={`${Constants.ACCOMMODATIONS_NAME} logo header`}
            id='nav_logo'
          />
        </div>
        <div className='nav_book'>
          <a
            href={Constants.bookNowLink}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>RESERVE NOW</div>
          </a>
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.HOME)}
            >
              home
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ABOUT)}
            >
              our story
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.AUTHENTICITY)}
            >
              rooftop bar
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.FEATURES)}
            >
              features
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.RESTAURANTS, true)}
            >
              restaurants
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.SUSTAINABILITY, true)}
            >
              sustainability
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ACCOMMODATION)}
            >
              accommodation
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.OFFERS)}
            >
              special offers
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.MICE, true)}
            >
              mice
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.WELLNESS, true)}
            >
              wellness
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.DESTINATIONS)}
            >
              destination
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.GALLERY)}
            >
              gallery
            </div>
            <div
              className='opacity_transition_high nav_menu_list_links_nested_parent'
              onClick={() => handleLink(Constants.GLANCE)}
            >
              at glance
            </div>
          </div>
          <div className='nav_menu_list_image'>
            <img src={menu} alt={`${Constants.ACCOMMODATIONS_NAME} menu`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
