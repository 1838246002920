import img1 from '../../../../assets/imgs/05_destination/destinationExperience/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/destinationExperience/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/destinationExperience/3.jpg';
import img4 from '../../../../assets/imgs/05_destination/destinationExperience/4.jpg';

const PLACE_1: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        SOLIN / SALONA AMPHITHEATER
      </h3>
      <p>
        Solin is the cradle of ancient Croatian history, located on the river
        Jadro, which we call the Croatian Jordan. It's the city with youngest
        population in the Republic of Croatia, and the one that hides a rich
        cultural and natural heritage, making it an interesting tourist
        destination. At the northwestern end of the Salonitan urban area, which
        is surrounded by walls, an amphitheater was built, directly integrated
        into the city's defense system.
      </p>
      <h3 className='title_underline_complementary_small'>KLIS</h3>
      <p>
        Klis Fortress rises on a bare cliff and consists of two parts. The
        western part is lower and from the north it is surmounted by the ridge
        hill, while the eastern part is higher and the round tower “Oprah”, the
        most important medieval stronghold of the fortress, stands out.
      </p>
      <h3 className='title_underline_complementary_small'>TROGIR</h3>
      <p>
        Trogir is a living Mediterranean town that offers countless
        opportunities for rest, relaxation, adventure and fun. Enter the city
        through the old city gates, take a leisurely walk around the city and
        let your imagination take you to the past.
      </p>
      <h3 className='title_underline_complementary_small'>OMIŠ</h3>
      <p>
        At the mouth of the river Cetina is the picturesque town of Omiš, the
        center of the Omiš Riviera, surrounded by small fishing villages with
        beautiful beaches. And for those who like active holidays, Omiš is the
        right destination: rafting on the river Cetina, free climbing,
        paragliding, diving, sailing, beach volleyball, tennis, etc.
      </p>
    </>
  ),
  imgPath: img1,
};

const PLACE_2: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>ŠIBENIK</h3>
      <p>
        Šibenik is located in the central Adriatic, in a picturesque, indented
        bay influenced by the Krka, one of the most beautiful Croatian karst
        rivers. Šibenik is recognizable for its cultural and historical sights
        and natural beauties and two national parks, Krka and Kornati.
      </p>
      <h3 className='title_underline_complementary_small'>ZADAR</h3>
      <p>
        Zadar is an ancient Mediterranean port city. The old town, surrounded by
        walls and towers on a peninsula, with a new, urban part is connected by
        a bridge, a symbol of the city.
      </p>
      <h3 className='title_underline_complementary_small'>MAKARSKA</h3>
      <p>
        The town of Makarska developed in a natural harbor enclosed from the
        southeast by Cape Osejava, and from the northwest by the peninsula of
        St. Peter. A recognizable symbol of Makarska is its sandy and pebble
        beach of 2 kilometers in length.
      </p>
      <h3 className='title_underline_complementary_small'>DUBROVNIK</h3>
      <p>
        Due to its exceptional beauty and rich cultural heritage, this ancient
        city is also included on the UNESCO World Heritage List under the
        category of Sights. Dubrovnik is surrounded by fortresses and
        fortifications and is a real treasure trove of architecture, culture,
        masterpieces carefully preserved through the centuries.
      </p>
    </>
  ),
  imgPath: img2,
};

const PLACE_3: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>ŠIBENIK</h3>
      <p>
        Šibenik is located in the central Adriatic, in a picturesque, indented
        bay influenced by the Krka, one of the most beautiful Croatian karst
        rivers. Šibenik is recognizable for its cultural and historical sights
        and natural beauties and two national parks, Krka and Kornati.
      </p>
      <h3 className='title_underline_complementary_small'>ZADAR</h3>
      <p>
        Zadar is an ancient Mediterranean port city. The old town, surrounded by
        walls and towers on a peninsula, with a new, urban part is connected by
        a bridge, a symbol of the city.
      </p>
      <h3 className='title_underline_complementary_small'>MAKARSKA</h3>
      <p>
        The town of Makarska developed in a natural harbor enclosed from the
        southeast by Cape Osejava, and from the northwest by the peninsula of
        St. Peter. A recognizable symbol of Makarska is its sandy and pebble
        beach of 2 kilometers in length.
      </p>
      <h3 className='title_underline_complementary_small'>DUBROVNIK</h3>
      <p>
        Due to its exceptional beauty and rich cultural heritage, this ancient
        city is also included on the UNESCO World Heritage List under the
        category of Sights. Dubrovnik is surrounded by fortresses and
        fortifications and is a real treasure trove of architecture, culture,
        masterpieces carefully preserved through the centuries.
      </p>
    </>
  ),
  imgPath: img3,
};

const ISLAND: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>BRAČ</h3>
      <p>
        The island of Brač has its own diverse interior, rich flora and fauna,
        and a more pronounced variety of the seasons in terms of climate.
        Despite its rich cultural heritage, Brač is most known for its beach
        Zlatni rat in Bol.
      </p>
      <h3 className='title_underline_complementary_small'>HVAR</h3>
      <p>
        The island of Hvar is a unique combination of magnificent Mediterranean
        nature, rich and layered cultural and historical heritage, and
        fashionable tourist modernity. The terraces and streets of island towns
        have been open stages of life and art for centuries.
      </p>
      <h3 className='title_underline_complementary_small'>VIS</h3>
      <p>
        The island of Vis is located on the open sea. There are two main towns
        on the island, Vis and Komiža, with many smaller settlements along the
        coast and inland. Popular places to visit include the Blue Cave and
        Stiniva Bay.
      </p>
      <h3 className='title_underline_complementary_small'>ŠOLTA</h3>
      <p>
        The island of Šolta is located nine nautical miles away from Split. With
        seven boat connections a day in the summer, it has practically become a
        suburb of Split.
      </p>
    </>
  ),
  imgPath: img4,
};

export const destinationExperienceList1: Array<Feature> = [
  PLACE_1,
  PLACE_2,
  PLACE_3,
];
export const destinationExperienceList2: Array<Feature> = [ISLAND];
