import servicesImg from '../../assets/imgs/07_services/servicesImgs/services.jpg';
import fitnessImg from '../../assets/imgs/07_services/servicesImgs/fitness.jpg';
import cyclistsImg from '../../assets/imgs/07_services/servicesImgs/cyclists.jpg';
import bikersImg from '../../assets/imgs/07_services/servicesImgs/bikers.jpg';
import petsImg from '../../assets/imgs/07_services/servicesImgs/pets.jpg';
import parkingImg from '../../assets/imgs/home/02_features/transfers.png';

import fitnessPdf from '../../assets/documents/04_services/Fitness & Wellbeing 2023.pdf';

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const SERVICES: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>SERVICES</h2>
      <ul>
        <li>Transfer services</li>
        <li>Luggage services</li>
        <li>E-charging services</li>
        <li>Room services</li>
        <li>Laundry services</li>
        <li>Pressing services</li>
      </ul>
      <p>
        <i>
          *The specified items are charged according to the applicable hotel
          rates. Laundry and pressing services are carried out exclusively by
          the hotel staff and/or authorized professionals.
        </i>
      </p>
    </>
  ),
  imgPath: servicesImg,
};

const FITNESS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>FITNESS</h2>
      <p>
        Our fitness center has everything you need to keep up your routine:
        cardio, strength and stretching equipment.
      </p>
      <a
        href={fitnessPdf}
        target='_blank'
        rel='noreferrer'
        className='opacity_transition_low button'
      >
        <div>READ MORE</div>
      </a>
    </>
  ),
  imgPath: fitnessImg,
};

const CYCLISTS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        SERVICES FOR CYCLISTS
      </h2>
      <ul>
        <li>Cycling maps</li>
        <li>Guarded bike storage</li>
        <li>Bike washing area</li>
        <li>Simple repairs tool kit</li>
        <li>Bike maintenance services</li>
        <li>Info point with information about local cycling offer</li>
        <li>Sports clothes laundry service</li>
        <li>Bike rental</li>
        <li>Guided bike tours</li>
        <li>Professional cycling equipment and devices</li>
        <li>GPS with points of interest</li>
        <li>Cyclist buffet breakfast</li>
      </ul>
      <p>
        <i>
          *The specified items are charged according to the applicable hotel
          rates.
        </i>
      </p>
    </>
  ),
  imgPath: cyclistsImg,
};

const BIKERS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        SERVICES FOR BIKERS
      </h2>
      <ul>
        <li>On-site parking</li>
        <li>Secure garage parking</li>
        <li>Laundry service</li>
        <li>Tour program for motorcycle-free days</li>
      </ul>
      <p>
        <i>
          *The specified items are charged according to the applicable hotel
          rates.
        </i>
      </p>
    </>
  ),
  imgPath: bikersImg,
};

const PETS: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>SERVICES FOR PETS</h2>
      <ul>
        <li>Pet bed placed in the room for use throughout the stay</li>
        <li>Food/water bowls placed in the room for use throughout the stay</li>
        <li>Disposable pick-up bags</li>
        <li>Fetching toy</li>
      </ul>
      <p>
        <i>
          *The specified items are charged according to the applicable hotel
          rates.
        </i>
      </p>
    </>
  ),
  imgPath: petsImg,
};

const PARKING: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        PARKING - ELECTRIC CHARGING
      </h2>
      <p>
        Always leading the way, Cornaro Hotel has installed electric car
        charging point in our car parking as part of our commitment to giving
        customers what they need whilst at the same time promoting better
        environmental practices for the region.
      </p>
      <p>
        The charging service at hotel is available complimentary to all hotel
        guests with parking fee, and helps ensure you enjoy your visit in the
        knowledge that you will not be caught short when wishing to explore the
        city of Split.
      </p>
      <p>
        We are committed to going the extra mile for our guests and this
        addition to our facilities demonstrates we are a forward-thinking team
        with an eye on the future.
      </p>
    </>
  ),
  imgPath: parkingImg,
};

const FeaturesList: Array<Feature> = [
  SERVICES,
  FITNESS,
  CYCLISTS,
  BIKERS,
  PETS,
  PARKING,
];

export default FeaturesList;
