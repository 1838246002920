import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import { useEffect } from 'react';
import Impressum from './pages/00_impressum/Impressum';
import Constants from './utils/Constants';
import Sustainability from './pages/02_sustainability/Sustainability';
import Restaurants from './pages/05_restaurants/Restaurants';
import Wellness from './pages/04_wellness/Wellness';
import Mice from './pages/03_mice/Mice';
import ExperiencePlace from './pages/06_destinations/components/experiencePlace/ExperiencePlace';
import ExperienceSplit from './pages/06_destinations/components/experienceSplit/ExperienceSplit';
import DestinationExperience from './pages/06_destinations/components/destinationExperience/DestinationExperience';
import ExcapeToCreate from './pages/06_destinations/components/excapeToCreate/ExcapeToCreate';
import DalmatianMind from './pages/06_destinations/components/dalmatianMind/DalmatianMind';
import TraditionalFood from './pages/06_destinations/components/traditionalFood/TraditionalFood';
import B2b from './pages/07_b2b/B2b';
import Services from './pages/08_services/Services';
import Experience from './pages/09_expirience/Experience';
import StoryBehindTheName from './pages/09_expirience/components/StoryBehindTheName';

const App: React.FC = () => {
  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    setHight();
  }, []);

  window.addEventListener('resize', setHight);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={Constants.IMPRESSUM} element={<Impressum />} />
          <Route path={Constants.SUSTAINABILITY} element={<Sustainability />} />
          <Route path={Constants.MICE} element={<Mice />} />
          <Route path={Constants.WELLNESS} element={<Wellness />} />
          <Route path={Constants.RESTAURANTS} element={<Restaurants />} />
          <Route
            path={Constants.EXPERIENCE_A_PLACE}
            element={<ExperiencePlace />}
          />
          <Route
            path={Constants.EXPERIENCE_SPLIT_SIGHTS_AND_CULTURE}
            element={<ExperienceSplit />}
          />
          <Route
            path={Constants.DESTINATION_EXPERIENCE}
            element={<DestinationExperience />}
          />
          <Route
            path={Constants.ESCAPE_TO_CREATE_YOUR_ACTIVITIES_IN_NATURE}
            element={<ExcapeToCreate />}
          />
          <Route
            path={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY}
            element={<TraditionalFood />}
          />
          <Route
            path={Constants.DALMATIAN_STATE_OF_MIND_LOCAL_WORDS}
            element={<DalmatianMind />}
          />
          <Route path={Constants.B2B} element={<B2b />} />
          <Route path={Constants.SERVICES} element={<Services />} />
          <Route path={Constants.EXPERIENCE} element={<Experience />} />
          <Route
            path={Constants.STORY_BEHIND_THE_NAME}
            element={<StoryBehindTheName />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
