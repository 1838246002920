import { useEffect } from 'react';
import Constants from '../../../../utils/Constants';
import heroImg from '../../../../assets/imgs/home/09_destinations/experience a place-destination.jpg';
import { CapitalizeFirstLeter } from '../../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import DestinationFeatures from '../../DestinationFeatures';
import {
  traditionalFoodList1,
  traditionalFoodList2,
  traditionalFoodList3,
  traditionalFoodList4,
  traditionalFoodList5,
} from './TraditionalFoodList';

const TraditionalFood = () => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.ACCOMMODATIONS_NAME
    )} - ${CapitalizeFirstLeter(
      Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY
    )}`;
  }, []);

  return (
    <div
      id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY}
      className='destination'
    >
      <div className='destination_hero'>
        <img
          src={heroImg}
          alt={`${Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY} hero`}
        />
        <div className='overlay'></div>
        <h1 className='destination_hero_title'>
          STORY OF TRADITIONAL FOOD AND CORNARO GASTRONOMY
        </h1>
      </div>
      <div className='destination_container container'></div>
      <br />
      <br />
      <ScrollAnimation animateIn='fadeInUp' animateOut='fadeOut' duration={500}>
        <div className='destination_subtext container'>
          <h3>
            Cornaro hotel is proudly named after the central bastion which is
            located inside the historic old city's pedestrian zone. Besides the
            defensive fortification and the palace itself, the hotel is
            surrounded by all of the main tourist attractions, museums,
            churches…
          </h3>
        </div>
        <DestinationFeatures
          id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY + '1'}
          list={traditionalFoodList1}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={200}>
          <div className='destination_subtext container'>
            <h2>THE MOST FAMOUS DALMATIAN DISHES</h2>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY + '2'}
          list={traditionalFoodList2}
          isReversed={true}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={350}>
          <div className='destination_subtext container'>
            <h2>DALMATIAN HERBS AS LIQUEURS</h2>
            <p>
              Croats make their beloved strong liqueur using all sorts of
              natural ingredients available to them, and many types of herbs
              contribute to the unique Dalmatian flavors of the hard stuff.
              Rakija is one of the most popular drinks in Croatia!
            </p>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY + '3'}
          list={traditionalFoodList3}
          isReversed={false}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={350}>
          <div className='destination_subtext container'>
            <h2>STORY OF DALMATIAN AROMATIC AND MEDICAL HERBS</h2>
            <p>
              Striving to resolve our health issues, we sometimes forget that
              the nature already gave us everything we may need to be healthy.
              Free natural medicaments are literally at arm's reach. Medicinal
              herbs with high levels of essential oils grow freely in the region
              of Dalmatia. Their natural products are used by Cornaro Hotel's
              health professionals in the Wellness area and, on the other side
              of the spectrum, by chefs and cooks preparing ingredients, spices
              and dressings.
            </p>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY + '4'}
          list={traditionalFoodList4}
          isReversed={true}
        />
        <ScrollAnimation animateIn='fadeInUp' delay={350}>
          <div className='destination_subtext container'>
            <h2>WINE STORY</h2>
            <p>
              The Nikolica Family Winery grew out of a centuries-old family
              tradition of growing vines and producing wine. The wines of
              Nikolica Winery are of premium quality, which is a proof of the
              long-standing viticultural and winemaking tradition in the region.
            </p>
          </div>
        </ScrollAnimation>
        <DestinationFeatures
          id={Constants.STORY_OF_TRADITIONAL_FOOD_AND_CORNARO_GASTRONOMY + '5'}
          list={traditionalFoodList5}
          isReversed={false}
        />
      </ScrollAnimation>
    </div>
  );
};

export default TraditionalFood;
