import Constants from '../../utils/Constants';
import ScrollAnimation from '../../utils/ScrollAnimation';
import heroImg from '../../assets/imgs/08_expirience/expirience.webp';
import cornaroBrandImg from '../../assets/imgs/08_expirience/lists/cornaro_brand.jpg';
import cornaroHotelImg from '../../assets/imgs/08_expirience/lists/cornaro_hotel.jpg';
import { experienceList, partnersList, rewardsList } from './ExperienceList';
import Slider from 'react-slick';

const Experience = () => {
  const slickSettingsImgs = {
    focusOnSelect: true,
    infinite: true,
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div id={Constants.EXPERIENCE} className='experience'>
      <div className='experience_hero'>
        <img src={heroImg} alt={`${Constants.EXPERIENCE} hero`} />
      </div>
      <div className='experience_container container'>
        <h1 className='text_center'>CORNARO EXPIRIENCE</h1>
        <h2 className='text_center'>
          As an urban lifestyle hotel in the city center, Cornaro Hotel reflects
          a vibrant atmosphere and pulsing rhythm of the ancient city of Split.
        </h2>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <div className='experience_grid_reverse'>
            <ScrollAnimation animateIn='fadeIn' delay={100}>
              <div className='experience_grid_img'>
                <img src={cornaroBrandImg} alt='cornaro brand' />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={150}>
              <div className='experience_grid_second'>
                <h2 className='title_underline_complementary_small'>
                  Cornaro brand
                </h2>
                <p>
                  The excellent central city location of our hotel, its ancient
                  history surroundings and interior elegance of luxurious and
                  comfortable rooms make Cornaro Hotel a vital part of the top
                  tourist and business offer of the city.
                </p>
                <p>
                  Whether it is for work, leisure or simply to refresh your
                  perspective, Cornaro Hotel is at your disposal, providing
                  everything you need, from warm hospitality to state-of-the-art
                  facilities. With a consistent improvement of our services and
                  our goal being 100% guest satisfaction, we strive to
                  demonstrate our utmost dedication to our guests.
                </p>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
        <h3 className='text_center'>
          It is our passion for personalized service which offers discreet,
          thoughtful, and unique attention to every guest, with just the right
          balance between professionalism and intimacy, that makes stay at
          Cornaro Hotel truly exquisite.
        </h3>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <div className='experience_grid'>
            <ScrollAnimation animateIn='fadeIn' delay={100}>
              <div className='experience_grid_img'>
                <img src={cornaroHotelImg} alt='cornaro hotel split' />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={150}>
              <div className='experience_grid_second'>
                <p>
                  This is what makes Cornaro Hotel the best choice for your next
                  visit:
                </p>
                <ul>
                  <li>excellent central city location</li>
                  <li>personalized, professional service</li>
                  <li>choice of tailor-made stays</li>
                  <li>relaxing and friendly atmosphere</li>
                  <li>modern, elegant interior</li>
                  <li>attention to details and cleanliness</li>
                  <li>24/7 Front Desk</li>
                  <li>concierge services</li>
                  <li>free and fast Wi-Fi connection</li>
                  <li>room service</li>
                  <li>wellness services</li>
                  <li>unforgettable buffet breakfast</li>
                  <li>exceptional A la Carte meals</li>
                  <li>extensive selection of Croatian and foreign wines</li>
                  <li>facilities overlooking the historical center</li>
                  <li>tourist attractions in the immediate vicinity</li>
                </ul>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
        <h2 className='text_center'>THE STORY BEHIND THE NAME</h2>
        <a
          href={Constants.STORY_BEHIND_THE_NAME}
          target='_blank'
          rel='noreferrer'
          className='button'
        >
          <div>READ THE STORY</div>
        </a>
        {experienceList.map((experience, index) => (
          <ScrollAnimation
            key={index}
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <div
              className={
                index % 2 === 0 ? 'experience_grid_reverse' : 'experience_grid'
              }
            >
              <ScrollAnimation animateIn='fadeIn' delay={100}>
                <div className='experience_grid_img'>
                  <img
                    src={experience.imgPath}
                    alt={experience.imgPath.split('/').pop()?.split('.')[0]}
                  />
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' delay={150}>
                <div className='experience_grid_text'>
                  {experience.description}
                </div>
              </ScrollAnimation>
            </div>
          </ScrollAnimation>
        ))}
        <h2 className='text_center'>
          A city of glorious architecture, entertainment and finest gastronomic
          and wine experiences!
        </h2>
        <a
          href={Constants.EXPERIENCE_A_PLACE}
          target='_blank'
          rel='noreferrer'
          className='button'
        >
          <div>ABOUT SPLIT</div>
        </a>
        <div className='experience_awards'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <h2 className='text_center'>AWARDS</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' delay={150}>
              <Slider {...slickSettingsImgs}>
                {rewardsList.map((reward) => (
                  <div className='eexperience_awards_slider_container'>
                    <div className='experience_awards_slider_container_img'>
                      <img
                        src={reward}
                        alt={reward.split('/').pop()?.split('.')[0]}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </ScrollAnimation>
          </ScrollAnimation>
        </div>
        <div className='experience_awards'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={100}
          >
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <h2 className='text_center'>PARTNERSHIP</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' delay={150}>
              <Slider {...slickSettingsImgs}>
                {partnersList.map((partner) => (
                  <div className='eexperience_awards_slider_container'>
                    <div className='experience_awards_slider_container_img'>
                      <img
                        src={partner}
                        alt={partner.split('/').pop()?.split('.')[0]}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </ScrollAnimation>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default Experience;
