import cornaroMissionImg from '../../assets/imgs/08_expirience/lists/cornaro_mission.jpg';
import cornaroValuesImg from '../../assets/imgs/08_expirience/lists/brand_values.png';

interface Feature {
  description: JSX.Element;
  imgPath: string;
}

const CORNARO_MISSION: Feature = {
  description: (
    <>
      <h2 className='title_underline_complementary_small'>
        Vision & mission statements
      </h2>
      <h3 className='title_underline_complementary_small'>Vision</h3>
      <p>
        To deliver a memorable experience for each guest by providing excellence
        in personal and quality service.
      </p>
      <h3 className='title_underline_complementary_small'>Mission</h3>
      <p>
        To be the first choice of guests staying in Split and to exceed their
        expectations by offering Croatian hospitality. Our attentive staff will
        satisfy the desires of the most demanding guests and make their stay in
        Split unforgettable. Personalized service which offers discreet,
        thoughtful, and unique attention to every guest with just the right
        balance between professionalism and intimacy is the mission of Cornaro
        Hotel.
      </p>
    </>
  ),
  imgPath: cornaroMissionImg,
};

const CORNARO_VALUES: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>
        Cornaro brand values
      </h3>
      <p>
        The three cusps in Cornaro Hotel logotype outline the historic Cornaro
        bastion and also symbolize the core values of our hotel and commitment
        to provide our guests with the best possible experience. By creating an
        inspiring work environment, we bring people together in order to foster
        big ideas and exceptional imagination, representing:
      </p>
      <h3 className='title_underline_complementary_small'>Improvement</h3>
      <p>
        Improving continuously, maintaining service standards to meet guest
        expectations and respect their values.
      </p>
      <h3 className='title_underline_complementary_small'>
        Connectivity technology, design and architecture
      </h3>
      <p>
        Connectivity provides state-of-the-art technology, expansive yet
        functional design and renowned, purpose-built architecture.
      </p>
      <h3 className='title_underline_complementary_small'>Teamwork</h3>
      <p>
        We stand together as a team in delivering quality service to our guests.
      </p>
    </>
  ),
  imgPath: cornaroValuesImg,
};

export const experienceList: Array<Feature> = [CORNARO_MISSION, CORNARO_VALUES];

const rewardsImgs = require.context(
  '../../assets/imgs/08_expirience/awards',
  true
);

export const rewardsList: Array<string> = rewardsImgs
  .keys()
  .map((image: any) => rewardsImgs(image));

const partnersImgs = require.context(
  '../../assets/imgs/08_expirience/partners',
  true
);

export const partnersList: Array<string> = partnersImgs
  .keys()
  .map((image: any) => partnersImgs(image));
