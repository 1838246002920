import img1 from '../../../../assets/imgs/05_destination/escapeToCreate/1.jpg';
import img2 from '../../../../assets/imgs/05_destination/escapeToCreate/2.jpg';
import img3 from '../../../../assets/imgs/05_destination/escapeToCreate/3.jpg';

const PLACE: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Walking</h3>
      <p>
        Marjan Forest Park has a large number of hiking trails. It is possible
        to walk in the northern part of Marjan, where traffic is prohibited,
        along the trim trails in the pine forest or the southern part with an
        excellent promenade with great views, and also explore old churches
        carved into the rocks known as “Marjan churches”.
      </p>
      <h3 className='title_underline_complementary_small'>Running</h3>
      <p>
        Marjan Forest Park is also ideal for running. Trim trails were created
        with exercise in mind first and foremost. Apart from Marjan, this type
        of recreation is also provided by the beaches Bačvice, Zenta, Žnjan and
        the Poljud Stadium.
      </p>
      <h3 className='title_underline_complementary_small'>Cycling</h3>
      <p>
        Once again, the Marjan Forest Park imposes itself as part of Split
        intended for the recreation of both the citizens of Split and its
        visitors. Along the entire Marjan hill there is a path for cyclists, and
        lately mountain biking enthusiasts have been enjoying its slopes as
        well.
      </p>
      <h3 className='title_underline_complementary_small'>Free climbing</h3>
      <p>
        Southern slopes of Marjan feature rocks which are ideal for fans of free
        climbing, be it in a climbing school or just for recreation on their
        own. If the indoor type of this sport suits you better, the
        long-standing climbing center of “Marulianus” is open at the Poljud
        Stadium all year round.
      </p>
    </>
  ),
  imgPath: img1,
};

const BEACH: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>Bene</h3>
      <p>
        Bene is located in the northeastern part of Marjan. This partly sandy
        beach located in a pine forest provides its visitors with natural shade.
      </p>
      <h3 className='title_underline_complementary_small'>Bačvice</h3>
      <p>
        “Let's go to Bačvice” is an anthem to this Split beach which officially
        opened in 1919. Bačvice is a natural phenomenon, a sandy beach in the
        heart of the city, right next to the city port, which can accommodate
        over ten thousand people.
      </p>
      <h3 className='title_underline_complementary_small'>Firule</h3>
      <p>
        From the tip of Marjan, i.e. from the Oceanographic Institute, you can
        walk along the sea to the beaches on the eastern perimeter of the city.
      </p>
      <h3 className='title_underline_complementary_small'>Žnjan</h3>
      <p>
        Žnjan beach is located between the beaches of Trstenik in the west and
        Duilovo in the east. It became very popular among the people of Split
        with the arrival of John Paul II in Split in 1998.
      </p>
      <h3 className='title_underline_complementary_small'>Kašjuni</h3>
      <p>
        From the southwestern part of Marjan emerges a beautiful beach of
        Kašjuni. This popular beach in Split is characterized by a large pebble
        bay. It is a favorite with families with children and young people.
      </p>
      <h3 className='title_underline_complementary_small'>
        Kaštelet and Ježinac{' '}
      </h3>
      <p>
        Two pebble beaches which connect to each other are also located at the
        foot of Marjan. Apart from being a favorite with families with small
        children, they are also suitable for other ages.
      </p>
    </>
  ),
  imgPath: img2,
};

const PICIGIN: Feature = {
  description: (
    <>
      <h3 className='title_underline_complementary_small'>PICIGIN</h3>
      <p>
        Picigin is an amateur sport invented in Split which is played with a
        ball on a sandy beach in the ankle-deep water. It's played by people of
        all ages. Today in Bačvice you can see the game being played all year
        round, even at night.
      </p>
    </>
  ),
  imgPath: img3,
};

export const excapeToCreateList1: Array<Feature> = [PLACE];
export const excapeToCreateList2: Array<Feature> = [BEACH];
export const excapeToCreateList3: Array<Feature> = [PICIGIN];
